import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import useProduct from '@/views/product/useProduct'
import productFilterModal from '@/modal/productFilterModal'

export default function useProductOfficeList() {
  const {
    productMenuType,
    productMenuTypeVI,
    productListMenuType,
    //
    exportExcel,
    deleteProduct,
    renderProductAddress,
    renderProductOfficeStructure,
  } = useProduct()

  const filter = productFilterModal()
  const products = ref([])
  const currentProduct = ref({})
  const created = ref(false)
  const exportExcelTime = ref(null)
  const timeFrom = ref(null)
  const timeTo = ref(null)
  const exportExcelCloseDeal = ref('')

  const userData = computed(() => store.state.app.userData)
  const districts = computed(() => store.state.app.districts)
  const wardsByDistricts = computed(() => store.state.app.wardsByDistricts)
  const streetsByDistricts = computed(() => store.state.app.streetsByDistricts)
  const users = computed(() => store.state.app.users)
  const routeName = computed(() => store.state.app.currentRouteName)

  const showImage160 = ref([])

  // Table products
  const tableColumns = ref([
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'minw-80 sticky-column-left',
      thClass: 'sticky-column-left',
    },
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Tên',
      key: 'name',
      thClass: 'minw-150',
    },
    {
      label: 'Địa chỉ',
      key: 'dia-chi',
      thClass: 'minw-200',
      formatter: (value, key, item) => renderProductAddress(item),
    },
    {
      label: 'Mô tả diện tích',
      key: 'rental_area_description',
      thClass: 'minw-250',
    },
    {
      label: 'Kết cấu',
      key: 'ket-cau',
      thClass: 'minw-150',
      formatter: (value, key, item) => renderProductOfficeStructure(item),
    },
    {
      label: 'Giá trọn gói',
      key: 'price',
    },
    {
      label: 'Diện tích thuê',
      key: 'floor_area',
    },
    {
      label: 'Thuê nguyên căn',
      key: 'is_rent_all_apartment',
      formatter: (value, key, item) => (item.is_rent_all_apartment ? 'Nguyên căn' : 'Không'),
    },
    {
      label: 'Ngày đăng',
      key: 'ngay-dang',
    },
    {
      label: 'Hành động',
      key: 'actions',
      tdClass: 'sticky-column-right text-right',
      thClass: 'sticky-column-right',
    },
  ])
  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const totalProducts = ref(0)

  const buildQuery = customFilter => http.buildQuery(customFilter || Object.assign(filter, {
    page: currentPage.value - 1,
    perpage: perPage.value,
    product_type: 'office',
    user_id: productListMenuType.value === 'list' ? filter.user_id : userData.value.id,
    currency: 'usd',
  })) || ''

  const fetchProducts = customFilter => {
    const queryString = buildQuery(customFilter)
    return http.handle(store.dispatch('product/fetchProducts', queryString), res => {
      products.value = res.data?.result || []
      totalProducts.value = res.data?.total
    })
  }

  const exportExcelProductOffice = () => exportExcel(buildQuery({
    type_product: productMenuType.value,
    close_deal_type: exportExcelCloseDeal.value,
    from: new Date(timeFrom.value).toISOString(),
    to: new Date(timeTo.value).toISOString(),
    user_id: productListMenuType.value === 'list' ? '' : userData.value.id,
  }))

  const clearFilter = initFilter => {
    Object.assign(filter, productFilterModal(), { province_id: 1, product_type: 'office' }, initFilter)
  }

  watch([currentPage, perPage], () => {
    fetchProducts()
  })

  watch(exportExcelTime, n => {
    const today = new Date()
    if (!n) {
      timeFrom.value = null
      timeTo.value = null
    } else if (n === 'week') {
      timeFrom.value = new Date(today.getTime() - 7 * 24 * 60 * 60000)
      timeTo.value = new Date(today)
    } else if (n === 'month') {
      timeFrom.value = new Date(today.getTime() - 30 * 24 * 60 * 60000)
      timeTo.value = new Date(today)
    }
  })

  watch(() => filter.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n }).then(res => {
      const arr = res.data?.data || []
      store.dispatch('app/fetchStreetsByDistricts', http.buildQuery({ district_ids: arr.map(item => item.id).join(',') }))
    })
    if (created.value) {
      filter.district_id_in = []
      filter.street_name_in = []
    }
  })
  watch(() => filter.district_id_in, n => {
    if (!n || !filter.province_id) return
    store.dispatch('app/fetchWardsByDistricts', http.buildQuery({ district_ids: n }))
    if (created.value) {
      filter.ward_id_in = []
    }
  })

  return {
    showImage160,
    filter,
    districts,
    wardsByDistricts,
    streetsByDistricts,
    users,
    productMenuType,
    productMenuTypeVI,
    totalProducts,
    products,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    userData,
    routeName,
    currentProduct,
    productListMenuType,
    created,
    timeFrom,
    timeTo,
    exportExcelTime,
    exportExcelCloseDeal,
    clearFilter,
    exportExcelProductOffice,
    buildQuery,
    // api
    fetchProducts,
    deleteProduct,
  }
}
